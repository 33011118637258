<template>
  <div class="page-wrapper">
    <div class="box-wrapper">
      <div class="error-content py-3">
        <h1 class="error-code text-primary font-weight-normal" style="font-size: 120px">403</h1>
        <h3 class="text-muted mb-5">Sinto muito, seu acesso não foi autorizado</h3>
        <div class="mb-5"><a class="btn btn-primary " href="/"><span class="btn-icon"><i class="material-icons">arrow_back</i>Homepage</span></a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page403'
}
</script>

<style>
  .box-wrapper {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center
  }
  .error-content {
    max-width: 540px;
    flex-basis: 540px;
    text-align: center;
  }
</style>
